.about-title {
  margin-bottom: 45px;

  h1 {
    font-size: 45px;
    margin-top: 0;
  }
}

.about-info {
  font-size: 18px;
  line-height: 24px;
}

.signature {
  h1 {
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Sacramento', cursive;
  }
}

.about-section {
  address {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  span {
    padding-right: 8px;
    font-weight: 700;
  }
}

//  and (max-width: 667px)
// @media only screen and (min-width: 320px) {
//   .about-title {
//     width: 370px;
//   }

//   .about-info {
//     font-size: 20px;
//     line-height: 32px;
//     width: 504px;
//   }

//   .signature {
//     h1 {
//       font-size: 52px;
//       margin-top: 45px;
//       margin-bottom: 40px;
//     }
//   }

//   .about-section {
//     address {
//       width: 504px;
//       font-size: 26px;
//       line-height: 46px;
//     }
//   }
// }
