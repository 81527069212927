#home-banner {
  background: url(../assets/deno_.png) no-repeat 0 0;

  background-position: right;
  background-size: 638px;
}

.banner-content {
  position: relative;
  width: 100%;
  height: 945px;
}
.banner-social {
  position: absolute;
  top: 40px;
}

.banner-info {
  position: absolute;
  font-family: "Montserrat", sans-serif;

  top: 50%;
  width: 100%;
  height: 100%;
  margin-top: -100px;

  h1 {
    background: #fff;
    width: fit-content;
    font-size: 72px;
    margin-bottom: 0;
    font-family: "Sacramento", cursive;
  }

  h2 {
    background: #fff;
    width: fit-content;
    font-size: 115px;
    margin-top: 0;
    line-height: 92px;
    margin-left: -8px;
    font-weight: 700;
    color: #2d3038;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h3 {
    background: #fff;
    width: fit-content;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 24px;

    font-weight: 700;
    color: #2d3038;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 529px) {
  #home-banner {
    background: none;
  }

  .banner-content {
    height: 228px;
  }

  .col-sm-9 {
    max-width: unset;
  }

  .banner-info {
    top: 85%;

    h1 {
      font-size: 45px;
      margin: 0;
      padding: 10px;
    }

    h2 {
      font-size: 50px;
      margin: -28px 0 0 -8px;
      line-height: 92px;
      font-weight: 700;
      color: #2d3038;
      padding: 10px;
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 12px;

      font-weight: 700;
      color: #2d3038;
      margin: -18px 0 0 0;
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 1421px) {
  .banner-info {
    // h1 {
    //   font-size: 42px;
    //   margin-bottom: 8px;
    // }

    h2 {
      width: min-content;
    }

    h3 {
      width: min-content;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .banner-info {
    h1 {
      font-size: 42px;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 35px;
      padding: 10px;
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 6px;

      font-weight: 700;
      color: #2d3038;
      margin: -18px 0 0 0;
    }
  }
}
