.portfolio {
  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
    box-shadow: 0 1.5rem 4rem rgba(#000, 0.15);
    overflow: hidden;

    &:hover .portfolio__overlay {
      top: 0;
    }

    &-title {
      position: absolute;
      top: 13%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 25px;
      font-weight: 800;
    }

    &-top {
      height: 90px;
      background-image: url("../assets/accelerate.png");
      background-size: cover;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    }
    &-top--1 {
      height: 90px;
      background-image: url("../assets/childcare.png");
      background-size: cover;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    }
    &-top--2 {
      height: 90px;
      background-image: url("../assets/burgetify-2.png");
      background-size: cover;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    }
    &-top--3 {
      height: 90px;
      background-image: url("../assets/fast-food.png");
      background-size: cover;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      margin-top: 20px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li:not(last-child) {
        font-size: 1.2rem;
        padding: 0.5rem;
        border-bottom: 1px solid #eee;
      }
    }
  }

  &__img {
    max-width: 100%;
    height: 245px;
    border: 1px solid #f1f1f1;
  }

  &__overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ef6e30;
    color: #fff;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -ms-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;

    &-desc {
      padding: 1rem 2rem;
      text-align: center;
      font-size: 20px;
    }

    &-cont {
      display: flex;
      flex-direction: column;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #000;
        height: 50px;
        width: 110px;
        text-transform: uppercase;
        background: #fff;
        margin-bottom: 16px;
        border-radius: 25px;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.1);
          letter-spacing: 2px;
        }
      }
    }
  }
}
