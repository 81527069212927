.address {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    border-bottom: 1px solid #f1f1f1;
    padding: 30px 0;
    // transition: all 3s ease-in-out ease-in;

    &:first-child {
      padding-top: 25px;
    }

    &:hover .icons {
      -webkit-transition: rotate(360deg);
      -moz-transition: rotate(360deg);
      -o-transition: rotate(360deg);
      transform: rotate(370deg);
    }
  }

  h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1.75rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 68px;
  }
}

.contact {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding-left: 50px;
  margin-top: 45px;

  &-info {
    margin-bottom: 15px;
  }

  & .icons {
    position: absolute;
    left: -50px;
    top: 0;
    font-size: 36px;
  }

  &:hover .icons {
    -webkit-transition: rotate(360deg);
    -moz-transition: rotate(360deg);
    -o-transition: rotate(360deg);
    transform: rotate(370deg);
  }
}

.tittle {
  position: relative;

  h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1.75rem;
  }
}

.form {
  &-group {
    margin-top: 20px;
    margin-bottom: 0;

    &__errors {
      padding-top: 6px;
      color: #c0392b;
    }

    textarea {
      resize: none;
      height: 125px;
    }
  }

  &-control {
    width: 100%;
    line-height: 45px;
    box-shadow: none;
    color: #2d3038;
    font-size: 14px;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;

    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &-control:focus {
    outline: none;
    border-color: #87c05e;
  }
}

.icon1 {
  color: #ed1c24;
  //   transform: rotate(90deg);
}

.icon2 {
  color: #b90162;
}
.icon3 {
  color: #3c70b4;
}

.icon4 {
  transform: rotate(350deg);
}
