body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  color: #2d3038;
  font-size: 1rem;
  background-color: #fff;
  text-align: left;
}

.container {
  width: 85%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

// @media only screen and (min-width: 1200px) {
//   .container {
//     max-width: 80%;
//   }
// }

// @media only screen and (min-width: 668px) and (max-width: 1199px) {
//   .container {
//     max-width: 80%;
//   }
// }

// @media only screen and (min-width: 320px) and (max-width: 667px) {
//   .container {
//     max-width: 80%;
//   }
// }
.row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

.message {
  flex-direction: column;
}

.name-email {
  display: flex;
  justify-content: space-between;
  width: 96%;
}

.u-padding-top {
  padding-top: 80px;
}
.u-padding-bottom {
  padding-bottom: 100px;
}

.col-sm-6 {
  position: relative;
  width: 45%;
  min-height: 1px;
  // padding-right: 15px;
  padding-left: 15px;
}

.col-sm-12 {
  position: relative;
  width: 94%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-md-4 {
  max-width: 30%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-8 {
  max-width: 65%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.bg-color {
  background-color: #f9f9f9 !important;
}

.icons {
  float: left;
  font-size: 36px;
  margin-right: 30px;
  color: #f7941d;
  -webkit-transition: rotate 5s ease-in-out;
  -moz-transition: rotate 5s ease-in-out;
  -o-transition: rotate 5s ease-in-out;
  transition: rotate 5s ease-in-out;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  &:focus {
    outline: none;
  }

  &-primary {
    color: #fff;
    padding: 10px 30px;
    font-size: 16px;
    background-color: #3c70b4;
    margin-top: 20px;
    border: 1px solid #3c70b4;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:hover {
      color: #3c70b4;
      background-color: #fff;
      border: 1px solid #3c70b4;
    }
  }
}

@media only screen and (max-width: 1308px) {
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col-md-4 {
    max-width: fit-content;
  }

  .section-content {
    max-width: unset !important;
  }

  .col-md-8 {
    max-width: 80%;
  }
}

@media only screen and (max-width: 667px) {
  .name-email {
    flex-direction: column;
  }
  .col-sm-6 {
    width: 96%;
    padding-left: 0;
  }
}
